const IMAGE_SIZES_1 = {
  width: 208,
  height: 167,
};

const IMAGE_SIZES_2 = {
  width: 245,
  height: 234,
};

const IMAGE_SIZES_3 = {
  width: 100,
  height: 160,
};

export const imageData = [
  {
    imageStyle: 'w-20 sm:w-24',
    containerStyle: 'bg-gray-30 p-2 absolute top-0 right-0 mr-10 sm:mr-0',
    ...IMAGE_SIZES_1,
  },
  {
    imageStyle: 'w-20 sm:w-32 sm:-ml-6',
    containerStyle:
      'bg-gray-30 p-2 absolute bottom-0 right-0 mb-36 sm:mr-10 sm:mb-0',
    ...IMAGE_SIZES_2,
  },
  {
    imageStyle: 'w-12 sm:w-16',
    containerStyle:
      'absolute mt-10 sm:mb-10 mr-8 sm:mr-2 top-0 sm:top-auto right-0 sm:bottom-0',
    ...IMAGE_SIZES_3,
  },
];
