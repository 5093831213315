import {useLoaderData} from '@remix-run/react';

import {twMerge} from '@/stylesheets/twMerge';
import ShopifyLogoIcon from '@/components/pages/oberlo/icons/ShopifyLogoIcon';
import {useSharedTranslations} from '@/hooks/useTranslations';
import Image from '@/components/pages/oberlo/components/Image/Image';
import Button from '@/components/pages/oberlo/components/LinkButton/LinkButton';
import {imageData} from '@/components/pages/oberlo/constants/Banner';

type ImageItemProps = {
  src: string;
  srcSet: string;
  alt: string;
};

interface ShopifyBannerProps {
  className?: string;
}

export const ShopifyBanner = ({className}: ShopifyBannerProps) => {
  const {t} = useSharedTranslations('pages/shopify_banner_content');
  const {t: tBannerSettings} = useSharedTranslations(
    'pages/shopify_banner_settings',
  );
  const data = useLoaderData<{canonicalUrl?: string; url?: string}>();
  const referringPageUrl = data?.canonicalUrl || data?.url || '';

  const logoImage = tBannerSettings('images.banner.logo');
  const bannerImages = tBannerSettings(
    'images.banner.images',
  ) as ImageItemProps[];

  return (
    <section
      className={twMerge(
        'bg-gray-10 px-8 -mx-8 md:pl-16 md:-mx-24 relative ',
        className,
      )}
      aria-label={t('banner.title')}
    >
      <div className="flex flex-col items-start py-12 gap-12 sm:gap-6 w-full sm:w-3/4">
        <Image
          className="hidden sm:block"
          srcSet={logoImage.srcSet}
          src={logoImage.src}
          width={80}
          height={23}
          alt={logoImage.alt}
        />
        <ShopifyLogoIcon className="sm:hidden w-10" />
        <p className="font-black text-display-lg font-display uppercase sm:text-size-display-sm sm:font-sans sm:normal-case sm:font-bold leading-tight w-3/4 sm:w-auto">
          {t('banner.title')}
        </p>
        <Button
          link={t('banner.link', {referringPageUrl})}
          type="secondary"
          className="btn-secondary normal-case w-full mt-2 hover:no-underline sm:w-auto sm:mt-0"
          data-event-schema="cta_banner_click"
          data-event-cta-id="default"
          data-event-cta-name="Default"
          data-event-template="default"
          data-event-block-index="1"
        >
          {t('banner.linkTitle')}
        </Button>
      </div>
      {bannerImages.map((image, index: number) => (
        <figure key={image.src} className={imageData[index].containerStyle}>
          <Image
            className={imageData[index].imageStyle}
            src={image.src}
            width={imageData[index].width}
            height={imageData[index].height}
            alt={image.alt}
          />
        </figure>
      ))}
    </section>
  );
};
